export interface BrowserInfo {
    browser: string;
    platform: string;
}

export function detectBrowserAndPlatform (): BrowserInfo {
    const userAgent = navigator.userAgent

    let browser = 'Unknown'
    let platform = 'Unknown'

    if (/chrome|crios|crmo/i.test(userAgent) && !/edge|edgios|edga|edg/i.test(userAgent)) {
        browser = 'Chrome'
    } else if (/firefox|iceweasel|fxios/i.test(userAgent)) {
        browser = 'Firefox'
    } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo/i.test(userAgent)) {
        browser = 'Safari'
    } else if (/edge|edgios|edga|edg/i.test(userAgent)) {
        browser = 'Edge'
    } else if (/opr\//i.test(userAgent)) {
        browser = 'Opera'
    } else if (/msie|trident/i.test(userAgent)) {
        browser = 'Internet Explorer'
    }

    if (/iphone|ipad|ipod/i.test(userAgent)) {
        platform = 'iOS'
    } else if (/android/i.test(userAgent)) {
        platform = 'Android'
    } else if (/win/i.test(userAgent)) {
        platform = 'Windows'
    } else if (/mac/i.test(userAgent)) {
        platform = 'MacOS'
    } else if (/linux/i.test(userAgent)) {
        platform = 'Linux'
    }

    return { browser, platform }
}